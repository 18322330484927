@tailwind base;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@import 'flatpickr/dist/flatpickr';
@import 'flatpickr/dist/plugins/monthSelect/style';

@import '../css/tom-select.tailwind.css';


[x-cloak] { display: none !important; }

.heading-underline {
    position: relative;
    z-index: 2;
    display: inline-block;
}

.heading-underline span {
    position: relative;
    z-index: 1;
}

.heading-underline div {
    position: absolute;
    height: 25%;
    @apply bg-red-200;
    width: 100%;
    bottom: 15%;
    left: 0;
    content: '';
    z-index: 0;
    transform: skewY(1.5deg);
}

input[type="text"]:disabled,
input[type="checkbox"]:disabled {
    @apply bg-gray-100 text-gray-500;
}


.draggable-mirror {
    .line-item-hide{
        @apply hidden absolute;
    }
    @apply flex bg-white w-full max-w-4xl;
}

.draggable--over{
    @apply bg-zinc-50;
}

.markdown-menu {
    @apply sticky top-0 space-y-2 text-sm text-gray-500;

    li {
        a:hover {
            @apply text-gray-900
        }
        ul {
            @apply pl-4 my-1 space-y-2 text-sm text-gray-500
        }
    }

}


.ts-control {
    height: 2.35rem;
    @apply rounded-md
}



.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    @apply bg-gray-500 border-gray-500 text-white shadow-none
}

.flatpickr-monthSelect-month.selected{
    @apply bg-gray-500 text-white
}

// @media print {
//     @page {
//         size:    A4 portrait;
//         margin: 20mm;
//     }
    
//     body {
//     width:   210mm;
//     height:  297mm;
//     }
// }


@tailwind utilities;