
.ts-wrapper.plugin-drag_drop.multi > .ts-control > div.ui-sortable-placeholder {
    visibility: visible !important;
    background: #f2f2f2 !important;
    background: rgba(0, 0, 0, 0.06) !important;
    border: 0 none !important;
    box-shadow: inset 0 0 12px 4px #fff;
}
.ts-wrapper.plugin-drag_drop .ui-sortable-placeholder::after {
    content: "!";
    visibility: hidden;
}
.ts-wrapper.plugin-drag_drop .ui-sortable-helper {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.plugin-checkbox_options .option input {
    margin-right: 0.5rem;
}

.plugin-clear_button .ts-control {
    padding-right: calc(1em + (3 * 5px)) !important;
}
.plugin-clear_button .clear-button {
    opacity: 0;
    position: absolute;
    top: 0.375rem;
    right: calc(0.75rem - 5px);
    margin-right: 0 !important;
    background: transparent !important;
    transition: opacity 0.5s;
    cursor: pointer;
}
.plugin-clear_button.single .clear-button {
    right: calc(0.75rem - 5px + 2rem);
}
.plugin-clear_button.focus.has-items .clear-button, .plugin-clear_button:hover.has-items .clear-button {
opacity: 1;
}

.ts-wrapper .dropdown-header {
position: relative;
padding: 6px 0.75rem;
border-bottom: 1px solid #d0d0d0;
background: #f8f8f8;
border-radius: 0.25rem 0.25rem 0 0;
}
.ts-wrapper .dropdown-header-close {
position: absolute;
right: 0.75rem;
top: 50%;
color: #343a40;
opacity: 0.4;
margin-top: -12px;
line-height: 20px;
font-size: 20px !important;
}
.ts-wrapper .dropdown-header-close:hover {
color: black;
}

.plugin-dropdown_input.focus.dropdown-active .ts-control {
box-shadow: none;
border: 1px solid #ced4da;
box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.plugin-dropdown_input .dropdown-input {
border: 1px solid #d0d0d0;
border-width: 0 0 1px 0;
display: block;
padding: 0.375rem 0.75rem;
box-shadow: none;
width: 100%;
background: transparent;
}
.plugin-dropdown_input.focus ~ .ts-dropdown .dropdown-input {
border-color: #86b7fe;
outline: 0;
/* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}

.ts-wrapper.plugin-input_autogrow.has-items .ts-control > input {
min-width: 0;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input {
flex: none;
min-width: 4px;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::-webkit-input-placeholder {
color: transparent;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::-ms-input-placeholder {
color: transparent;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::placeholder {
color: transparent;
}

.ts-dropdown.plugin-optgroup_columns .ts-dropdown-content {
display: flex;
}
.ts-dropdown.plugin-optgroup_columns .optgroup {
border-right: 1px solid #f2f2f2;
border-top: 0 none;
flex-grow: 1;
flex-basis: 0;
min-width: 0;
}
.ts-dropdown.plugin-optgroup_columns .optgroup:last-child {
border-right: 0 none;
}
.ts-dropdown.plugin-optgroup_columns .optgroup:before {
display: none;
}
.ts-dropdown.plugin-optgroup_columns .optgroup-header {
border-top: 0 none;
}

.ts-wrapper.plugin-remove_button .item {
display: inline-flex;
align-items: center;
padding-right: 0 !important;
}
.ts-wrapper.plugin-remove_button .item .remove {
color: inherit;
text-decoration: none;
vertical-align: middle;
display: inline-block;
padding: 0 5px;
border-left: 1px solid #dee2e6;
border-radius: 0 2px 2px 0;
box-sizing: border-box;
margin-left: 5px;
}
.ts-wrapper.plugin-remove_button .item .remove:hover {
background: rgba(0, 0, 0, 0.05);
}
.ts-wrapper.plugin-remove_button .item.active .remove {
border-left-color: rgba(0, 0, 0, 0);
}
.ts-wrapper.plugin-remove_button.disabled .item .remove:hover {
background: none;
}
.ts-wrapper.plugin-remove_button.disabled .item .remove {
border-left-color: white;
}
.ts-wrapper.plugin-remove_button .remove-single {
position: absolute;
right: 0;
top: 0;
font-size: 23px;
}

.ts-wrapper {
position: relative;
}

.ts-dropdown,
.ts-control,
.ts-control input {
color: #343a40;
font-family: inherit;
font-size: inherit;
line-height: 1.5;
font-smoothing: inherit;
}

.ts-control,
.ts-wrapper.single.input-active .ts-control {
background: #fff;
cursor: text;
}

.ts-control {
border: 1px solid #ced4da;
padding: 0.375rem 0.75rem;
width: 100%;
overflow: hidden;
position: relative;
z-index: 1;
box-sizing: border-box;
box-shadow: none;
border-radius: 0.25rem;
display: flex;
flex-wrap: wrap;
}
.ts-wrapper.multi.has-items .ts-control {
padding: calc( 0.375rem - 1px - 0px) 0.75rem calc( 0.375rem - 1px - 3px - 0px);
}
.full .ts-control {
background-color: #fff;
}
.disabled .ts-control, .disabled .ts-control * {
cursor: default !important;
}
.focus .ts-control {
box-shadow: none;
}
.ts-control > * {
vertical-align: baseline;
display: inline-block;
}
.ts-wrapper.multi .ts-control > div {
cursor: pointer;
margin: 0 3px 3px 0;
padding: 1px 5px;
background: #efefef;
color: #343a40;
border: 0px solid #dee2e6;
}
.ts-wrapper.multi .ts-control > div.active {
background: #0d6efd;
color: #fff;
border: 0px solid rgba(0, 0, 0, 0);
}
.ts-wrapper.multi.disabled .ts-control > div, .ts-wrapper.multi.disabled .ts-control > div.active {
color: #878787;
background: white;
border: 0px solid white;
}
.ts-control > input {
flex: 1 1 auto;
min-width: 7rem;
display: inline-block !important;
padding: 0 !important;
min-height: 0 !important;
max-height: none !important;
max-width: 100% !important;
margin: 0 !important;
text-indent: 0 !important;
border: 0 none !important;
background: none !important;
line-height: inherit !important;
-webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
        user-select: auto !important;
box-shadow: none !important;
}
.ts-control > input::-ms-clear {
display: none;
}
.ts-control > input:focus {
outline: none !important;
}
.has-items .ts-control > input {
margin: 0 4px !important;
}
.ts-control.rtl {
text-align: right;
}
.ts-control.rtl.single .ts-control:after {
left: calc(0.75rem + 5px);
right: auto;
}
.ts-control.rtl .ts-control > input {
margin: 0 4px 0 -2px !important;
}
.disabled .ts-control {
opacity: 0.5;
background-color: #e9ecef;
}
.input-hidden .ts-control > input {
opacity: 0;
position: absolute;
left: -10000px;
}

.ts-dropdown {
position: absolute;
top: 100%;
left: 0;
width: 100%;
z-index: 10;
border: 1px solid #d0d0d0;
background: #fff;
margin: 0.25rem 0 0 0;
border-top: 0 none;
box-sizing: border-box;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 0 0 0.25rem 0.25rem;
}
.ts-dropdown [data-selectable] {
cursor: pointer;
overflow: hidden;
}
.ts-dropdown [data-selectable] .highlight {
background: rgba(255, 237, 40, 0.4);
border-radius: 1px;
}
.ts-dropdown .option,
.ts-dropdown .optgroup-header,
.ts-dropdown .no-results,
.ts-dropdown .create {
padding: 3px 0.75rem;
}
.ts-dropdown .option, .ts-dropdown [data-disabled], .ts-dropdown [data-disabled] [data-selectable].option {
cursor: inherit;
opacity: 0.5;
}
.ts-dropdown [data-selectable].option {
opacity: 1;
cursor: pointer;
}
.ts-dropdown .optgroup:first-child .optgroup-header {
border-top: 0 none;
}
.ts-dropdown .optgroup-header {
color: #6c757d;
background: #fff;
cursor: default;
}
.ts-dropdown .create:hover,
.ts-dropdown .option:hover,
.ts-dropdown .active {
background-color: #e9ecef;
color: #1e2125;
}
.ts-dropdown .create:hover.create,
.ts-dropdown .option:hover.create,
.ts-dropdown .active.create {
color: #1e2125;
}
.ts-dropdown .create {
color: rgba(52, 58, 64, 0.5);
}
.ts-dropdown .spinner {
display: inline-block;
width: 30px;
height: 30px;
margin: 3px 0.75rem;
}
.ts-dropdown .spinner:after {
content: " ";
display: block;
width: 24px;
height: 24px;
margin: 3px;
border-radius: 50%;
border: 5px solid #d0d0d0;
border-color: #d0d0d0 transparent #d0d0d0 transparent;
animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

.ts-dropdown-content {
overflow-y: auto;
overflow-x: hidden;
max-height: 200px;
overflow-scrolling: touch;
scroll-behavior: smooth;
}

.ts-hidden-accessible {
border: 0 !important;
clip: rect(0 0 0 0) !important;
-webkit-clip-path: inset(50%) !important;
        clip-path: inset(50%) !important;
height: 1px !important;
overflow: hidden !important;
padding: 0 !important;
position: absolute !important;
width: 1px !important;
white-space: nowrap !important;
}

.ts-wrapper.form-control,
.ts-wrapper.form-select {
padding: 0 !important;
}

.ts-dropdown,
.ts-dropdown.form-control,
.ts-dropdown.form-select {
height: auto;
padding: 0;
z-index: 1000;
background: #fff;
border: 1px solid rgba(0, 0, 0, 0.15);
border-radius: 0.25rem;
box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.ts-dropdown .optgroup-header {
font-size: 0.875rem;
line-height: 1.5;
}
.ts-dropdown .optgroup:first-child:before {
display: none;
}
.ts-dropdown .optgroup:before {
content: " ";
display: block;
height: 0;
margin: 0.5rem 0;
overflow: hidden;
border-top: 1px solid rgba(0, 0, 0, 0.15);
margin-left: -0.75rem;
margin-right: -0.75rem;
}
.ts-dropdown .create {
padding-left: 0.75rem;
}

.ts-dropdown-content {
padding: 5px 0;
}

.ts-control {
min-height: calc(1.5em + 0.75rem + 2px);
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
display: flex;
align-items: center;
}
@media (prefers-reduced-motion: reduce) {
.ts-control {
    transition: none;
}
}
.ts-control.dropdown -active {
border-radius: 0.25rem;
}
.focus .ts-control {
border-color: #86b7fe;
outline: 0;
/* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}
.ts-control .item {
display: flex;
align-items: center;
}

.is-invalid .ts-control,
.was-validated .invalid .ts-control {
border-color: #dc3545;
}
.focus .is-invalid .ts-control,
.focus .was-validated .invalid .ts-control {
border-color: #bd2130;
box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.is-valid .ts-control {
border-color: #198754;
}
.focus .is-valid .ts-control {
border-color: #198754;
box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.input-group-sm > .ts-wrapper .ts-control, .ts-wrapper.form-select-sm .ts-control, .ts-wrapper.form-control-sm .ts-control {
min-height: calc(1.5em + 0.5rem + 2px);
padding: 0 0.75rem;
border-radius: 0.2rem;
font-size: 0.875rem;
}
.input-group-sm > .ts-wrapper.has-items .ts-control, .ts-wrapper.form-select-sm.has-items .ts-control, .ts-wrapper.form-control-sm.has-items .ts-control {
min-height: calc(1.5em + 0.5rem + 2px) !important;
font-size: 0.875rem;
padding-bottom: 0;
}
.input-group-sm > .ts-wrapper.multi.has-items .ts-control, .ts-wrapper.form-select-sm.multi.has-items .ts-control, .ts-wrapper.form-control-sm.multi.has-items .ts-control {
padding-top: calc((calc(1.5em + 0.5rem + 2px) - (1.5 * 0.875rem) - 4px) / 2) !important;
}
.ts-wrapper.multi.has-items .ts-control {
padding-left: calc(0.75rem - 5px);
padding-right: calc(0.75rem - 5px);
}
.ts-wrapper.multi .ts-control > div {
border-radius: calc(0.25rem - 1px);
}
.input-group-lg > .ts-wrapper > .ts-control, .ts-wrapper.form-control-lg .ts-control, .ts-wrapper.form-select-lg .ts-control {
min-height: calc(1.5em + 1rem + 2px);
border-radius: 0.3rem;
font-size: 1.25rem;
}

.ts-wrapper:not(.form-select) {
padding: 0;
border: none;
height: auto;
box-shadow: none;
background: none;
}
.ts-wrapper:not(.form-select).single .ts-control {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
background-position: right 0.5rem center;
background-repeat: no-repeat;
background-size: 1.5em 1.5em;
padding-right: 2.5rem;
-webkit-print-color-adjust: exact;
color-adjust: exact;
}

.ts-wrapper.form-select {
padding: 0;
height: auto;
box-shadow: none;
}
.ts-wrapper.form-select .ts-control, .ts-wrapper.form-select.single.input-active .ts-control {
border: none !important;
background: transparent !important;
}

.input-group > .ts-wrapper {
flex-grow: 1;
}
.input-group > .ts-wrapper:not(:nth-child(2)) > .ts-control {
border-top-left-radius: 0;
border-bottom-left-radius: 0;
}
.input-group > .ts-wrapper:not(:last-child) > .ts-control {
border-top-right-radius: 0;
border-bottom-right-radius: 0;
}

.ts-control{
    @apply h-10 py-2 px-3;
}
